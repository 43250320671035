import React from "react";
import "./VendorStaffing.css";
import Header from "../Header/Header";
import Breadcrumbs from "../BreadCrumb/BreadCrumb";

const data = {
  pageOne: {
    name: 'Home',
    path: '',
  },
  pageTwo: {
    name: 'Services',
    path: 'services',
  },
  pageThree: {
    name: 'Vendor Staffing',
    path: '',
  }
}

const AboutUs = () => {
  return (
    <div className="vendor-staffing-us-wrapper">
      <Header />
      <div className="vendor-staffing-top-section">
      </div>
      <div><Breadcrumbs data={data} /></div>
      <section id="vendor-staffing" className="vendor-staffing">
      <div className="section-title">
          <h2 className="container">Vendor On Premise Staffing Services</h2>
          <Breadcrumbs data={data} />
        </div>
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="row content">
          <p>A vendor on premise service is the next step for many businesses that have been consistently using a staffing agency. A VOP will work on-site at your office, warehouse, or other location to manage your company’s staffing and employee management needs.</p>

            <p className="heading">Vendor on Premise Services for Employers</p>
            <p>Vendor on premise services benefit organizations by managing HR-related tasks to allow a company’s owners and employees to focus on their own projects, goals and responsibilities. Some of the duties your on-site coordinator can take on include:</p>
            <ul>
            <li>Recruiting, screening and training new employees</li>
            <li>Streamlining the interview process</li>
            <li>Managing payroll, taxes, benefits and timekeeping</li>
            <li>Providing staff-related reports on attendance, cost, performance and more</li>
            <li>Conducting employee reviews</li>
            <li>Handling employee-related issues and disciplinary measures</li>
            <li>And more</li>
            <span>Whether you need to hire new employees or would like to delegate all the work that comes along with managing your existing team, a vendor on premise service is an effective solution.</span>
            </ul>
            <p>When you need reliable, effective vendor on premise services for your IT organization. We serve Fortune 500/1000-class clients all across the country, and we can provide your company with the same superior service.</p>


          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
