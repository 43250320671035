import React from "react";
import "./EngineeringServices.css";
import Header from "../Header/Header";
import Breadcrumbs from "../BreadCrumb/BreadCrumb";

const data = {
  pageOne: {
    name: 'Home',
    path: '',
  },
  pageTwo: {
    name: 'Services',
    path: 'services',
  },
  pageThree: {
    name: 'Engineering Services',
    path: '',
  }
}

const AboutUs = () => {
  return (
    <div className="engineering-services-us-wrapper">
      <Header />
      <div className="engineering-services-top-section">
      </div>
      <div><Breadcrumbs data={data} /></div>
      <section id="cengineering-services" className="engineering-services">
        <div className="section-title">
          <h2 className="container">IT Engineering Services Industries Knowledge</h2>
          <Breadcrumbs data={data} />
        </div>
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="row content">
            <p>TalentCloudSoft has been learning the IT staffing needs of a wide variety of industries, including engineering industries. Some of the biggest companies in the world, including Fortune 1000 companies, turn to TalentCloudSoft whenever they have staffing needs. These are businesses at the top of their market in areas like finance, life sciences, healthcare and engineering. If they trust us to staff their companies properly, you know you can, too. </p>
            <p>TalentCloudSoft has established long-term relationships with some of the biggest and best known firms in the US as the go-to staffing company for top-flight IT professionals on the cutting edge of technology and specific industry experience. We’ll ensure your hiring team makes the right choice. Your IT staff are too essential to leave to chance. Let us help you hire the best. Get in touch with TalentCloudSoft today.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
