import React from 'react';
import { useHistory } from "react-router-dom";
import './BreadCrumb.css';

const Breadcrumbs = (props) => {
  const history = useHistory();
  const { data } = props;
  const keys = Object.keys(data);
  return (
    <div className="container breadCrumb">
      {
        keys.map((item, i) => {
          if ((keys.length === i + 1)) {
            return (
              <span className="currentPage" key={Math.random()}>
                {data[keys[i]].name}
              </span>
            )
          } else {
            return (
              <span key={Math.random()}>
                <span className="firstPage" onClick={() => {
                  history.push(`/${data[keys[i]].path}`);
                }}>
                  {data[keys[i]].name}
                </span>
                <span className="currentPage">&gt;</span>
              </span>
            )
          }
        })
      }
    </div>
  )
}

export default Breadcrumbs;
