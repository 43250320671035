import React, { useState } from "react";
import emailjs from 'emailjs-com';
import "./Contact.css";
import Header from "../Header/Header";
import apiKeys from "../utils/emailApiKeys";
import 'antd/dist/antd.css';
import { TailSpin } from "react-loader-spinner";
import Breadcrumbs from "../BreadCrumb/BreadCrumb";

const key = 'updatable';

const data = {
  pageOne: {
    name: 'Home',
    path: '',
  },
  pageTwo: {
    name: 'Contact',
    path: 'contact',
  }
}

const Contact = () => {

  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false);
  const [overlay, setOverlay] = useState({ style: { display: 'none' } })
  const [overlayMsg, setOverlaymsg] = useState('');

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
  }

  const showOverlayForThreeSeconds = async () => {
    const sstyle = { style: { display: 'block' } }
    setOverlay(sstyle)
    await sleep(1000)
    const hstyle = { style: { display: 'none' } }
    setOverlay(hstyle)
  }

  const sendMessage = () => {
    const templateParams = {
      user_email: email,
      name: name,
      subject: subject,
      message: message
    };
    setLoading(true);

    emailjs.send(apiKeys.SERVICE_ID, apiKeys.TEMPLATE_ID, templateParams, apiKeys.USER_ID)
      .then((response) => {
        setLoading(false);
        console.log('SUCCESS!', response.status, response.text);
        showOverlayForThreeSeconds()
        setOverlaymsg('Mail Sent Seccessfully')
        setEmail("")
        setName("")
        setSubject("")
        setMessage("")
      }, (err) => {
        setLoading(false);
        console.log('FAILED...', err);
        showOverlayForThreeSeconds()
        setOverlaymsg('Sending Mail Failed')
      });

  }

  return (
    <div className="contact-wrapper">
      <Header />
      <div className="contact-top-section">
      </div>
      <section id="contact" className="contact">
        <div className="section-title">
          <h2 className="container">Contact</h2>
          <Breadcrumbs data={data} />
        </div>
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="contact-container">
            {loading ?
              <div className="loader">
                <TailSpin
                  color="#00BFFF"
                  visible={loading}
                />
              </div>
              :
              <div className="contact-message-container">
              <div className="second-row mb-20">
                <h4>
                  Write to us
                </h4>
              </div>
                <div className="second-row mb-20">
                  <div className="contact-message-name">
                    <label className="contact-message-name-label">
                      Your Name
                    </label>
                    <input
                      type="text"
                      value={name}
                      className="contact-message-name-input"
                      onChange={(e) => { setName(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="second-row mb-20">
                  <div className="contact-message-email">
                    <label className="contact-message-email-label">
                      Your Email
                    </label>
                    <input
                      type="text"
                      value={email}
                      className="contact-message-email-input"
                      onChange={(e) => { setEmail(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="second-row mb-20">
                  <div className="contact-message-subject">
                    <label className="contact-message-subject-label">
                      Subject
                    </label>
                    <input
                      type="text"
                      value={subject}
                      className="contact-message-subject-input"
                      onChange={(e) => { setSubject(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="third-row mb-20">
                  <div className="contact-message">
                    <label className="contact-message-label">Message</label>
                    <textarea
                      className="contact-message-textarea"
                      name="contactMessage"
                      value={message}
                      rows="9"
                      onChange={(e) => { setMessage(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="send-message">
                  <button
                    className="send-message-button"
                    onClick={sendMessage}
                    disabled={name === "" || email === "" || subject === "" || message === ""}
                  >Send Message</button>
                </div>
              </div>
            }
          </div>
          <div className='overlay' style={overlay.style}>
            <div className='overlay-image'>
              <span className='overlay-text'>{overlayMsg}</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Contact;
