import React from "react";
import "./CloudComputing.css";
import Header from "../Header/Header";
import Breadcrumbs from "../BreadCrumb/BreadCrumb";

const data = {
  pageOne: {
    name: 'Home',
    path: '',
  },
  pageTwo: {
    name: 'Services',
    path: 'services',
  },
  pageThree: {
    name: 'Cloud Computing',
    path: '',
  }
}

const CloudComputing = () => {
  return (
    <div className="cloud-computing-us-wrapper">
      <Header />
      <div className="cloud-computing-top-section">
      </div>
      <div><Breadcrumbs data={data} /></div>
      <section id="cloud-computing" className="cloud-computing">
        <div className="section-title">
          <h2 className="container">Cloud Computing Staffing Services</h2>
          <Breadcrumbs data={data} />
        </div>
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="row content">
            <p>Major corporations and smaller businesses across all industries are searching for ways to implement and integrate Cloud technology. TalentCloudSoft has multiple Cloud computing service offerings designed to recruit the talent and skill necessary for Cloud and IT growth. </p>
            <p>We are committed to cost-effective and cutting-edge innovation that will allow businesses to stay competitive in the fast-paced market. As the Cloud has proven to be a sustainable computing architecture, TalentCloudSoft has helped many of our clients adopt Cloud Computing as a fundamental part of their strategy to limit IT infrastructure and management costs while optimizing the sharing of application and infrastructure resources across multiple organizations.</p>
            <p>IT business services require highly trained candidates who understand the industry and necessary technologies. With our Cloud-based staffing software, we place qualified candidates to fill job openings in IT positions in business environments. Cloud computing openings are some of the most demanding positions in the industry due to the evolution of the Internet and data-based business processes.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CloudComputing;
