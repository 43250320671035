import React from "react";
import "./Services.css";
import Header from "../Header/Header";
import Breadcrumbs from "../BreadCrumb/BreadCrumb";
import { useHistory } from "react-router-dom";

const data = {
  pageOne: {
    name: 'Home',
    path: '',
  },
  pageTwo: {
    name: 'Services',
    path: 'services',
  }
}

const Services = () => {
  const history = useHistory();
  return (
    <div className="services-wrapper">
      <div className="services-top-section"></div>
      <Header />
      <section id="services" className="services">
        <div className="section-title">
          <h2 className="container">Services</h2>
          <Breadcrumbs data={data} />
        </div>
        <div className="container aos-init aos-animate services-container" data-aos="fade-up">
        <div className="services-box-wrapper">
            <div className="services-box">
              <div class="elixir-caret"></div>
              <div className="services-box-image1"></div>
              <div className="services-box-content1">
                <div className="services-box-header">
                  CLOUD COMPUTING
                </div>
                <div className="services-box-content">
                  Major corporations and smaller businesses across all industries are searching for ways to implement and integrate Cloud technology. TalentCloudSoft has multiple Cloud computing....
                </div>
                <div className="services-box-link" onClick={() => {
                  history.push("/services/cloud-computing");
                }}>
                  Learn More...
                </div>
              </div>
            </div>
            <div className="services-box  services-box-2">
              <div class="elixir-caret"></div>
              <div className="services-box-content2">
              <div className="services-box-header">
                  ENGINEERING SERVICES
                </div>
                <div className="services-box-content">
                TalentCloudSoft has been learning the IT staffing needs of a wide variety of industries, including engineering industries. Some of the biggest companies....
                </div>
                <div className="services-box-link" onClick={() => {
                  history.push("/services/engineering-services");
                }}>
                  Learn More...
                </div>
              </div>
              <div className="services-box-image2"></div>
            </div>
            <div className="services-box">
              <div class="elixir-caret"></div>
              <div className="services-box-image3"></div>
              <div className="services-box-content3">
              <div className="services-box-header">
                VENDOR STAFFING
                </div>
                <div className="services-box-content">
                A vendor on premise service is the next step for many businesses that have been consistently using a staffing agency. A VOP will work on-site at your office, warehouse, or other location....
                </div>
                <div className="services-box-link" onClick={() => {
                  history.push("/services/vendor-staffing");
                }}>
                  Learn More...
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
